@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-layout;
}

.wrapped-content {
  @apply px-4 md:px-12 xl:px-32 2xl:px-52;
}

.grecaptcha-badge {
  display: none !important;
}

.swiper {
  --swiper-pagination-color: #ffda7d;
  --swiper-pagination-bullet-inactive-color: #d9d9d9;
  --swiper-pagination-bullet-inactive-opacity: 1;
}
#custom-carousel {
  .swiper-pagination-bullet {
    @apply size-2 md:size-4;
  }
}

@keyframes toggle-clubs {
  0% {
    top: 10px;
    right: 140px;
    transform: rotate(-45deg);
  }
  40% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
  }
  60% {
    top: 0;
    right: 0;
    transform: rotate(0deg);
  }
  100% {
    top: 10px;
    right: 140px;
    transform: rotate(-45deg);
  }
}

@keyframes toggle-hearts {
  0% {
    bottom: 0;
    right: 230px;
  }
  40% {
    bottom: 0;
    right: 60px;
  }
  60% {
    bottom: 0;
    right: 60px;
  }
  100% {
    bottom: 0;
    right: 230px;
  }
}

@keyframes toggle-spades {
  0% {
    top: 10px;
    left: 140px;
    transform: rotate(45deg);
  }
  40% {
    top: 0;
    left: 0;
    transform: rotate(0deg);
  }
  60% {
    top: 0;
    left: 0;
    transform: rotate(0deg);
  }
  100% {
    top: 10px;
    left: 140px;
    transform: rotate(45deg);
  }
}

@keyframes toggle-diamonds {
  0% {
    bottom: 0;
    left: 230px;
  }
  40% {
    bottom: 0;
    left: 60px;
  }
  60% {
    bottom: 0;
    left: 60px;
  }
  100% {
    bottom: 0;
    left: 230px;
  }
}

.animated-container {
  position: absolute;
  z-index: -11;
  height: 397px;
  width: 704px;
  left: 50%;
  transform: translateX(-50%);
}

.card-image {
  position: absolute;
}

.card-clubs {
  animation: toggle-clubs 5s infinite ease-in-out;
}

.card-hearts {
  animation: toggle-hearts 5s infinite ease-in-out;
}

.card-spades {
  animation: toggle-spades 5s infinite ease-in-out;
}

.card-diamonds {
  animation: toggle-diamonds 5s infinite ease-in-out;
}
